@use "partials/accent";
@use "type/title";
@use "type/copy";
@use "type/label";
@use "partials/oneline";

.avatar {
	@extend %accent;
	position: relative;
	background-color: var(--action);
	width: 4rem;
	height: 4rem;
	border-radius: 50% 0 0 50%;
	overflow: hidden;
	flex-shrink: 0;

	&::before, &::after {
		content: '';
		position: absolute;
		inset-block: -20%;
		width: 70%;
	}

	&::before {
		right: 50%;
		background-color: var(--left);
		transform-origin: right center;
	}

	&::after {
		left: 50%;
		background: var(--right);
		transform-origin: left center;
	}
}

.avatar-image {
	position: absolute;
	z-index: 1;
	inset: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;

	&[aria-hidden='true'] {
		display: none
	}
}

.profile {
	display: flex;
	align-items: stretch;
	column-gap: 1rem;
	isolation: isolate;
	text-align: left;
}

.profile-info {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	row-gap: 0.25rem;
}

.profile-title {
	@extend %title;
	display: flex;
	align-items: center;
	column-gap: 1rem;
	color: var(--text-primary);
	height: 1.5rem;
	white-space: nowrap;

	[data-verified="true"] & {
		&::after {
			content: '';
			background-image: url("/images/checkmark.svg");
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}

.profile-name {
	@extend %copy;
	color: var(--text-primary);
}

.profile-location {
	@extend %label;
}

.profile-categories {
	@extend %copy, %oneline;
	padding: 0;
	list-style: none;
}

.profile-category {
	display: inline;
	color: var(--action);
	text-transform: capitalize;

	&:not(:last-child) {
		&::after {
			content: ', ';
		}
	}
}