@use "vars/palette";
@use "vars/dimen";
@use "partials/accessibility";
@use "type/display";

#app-header {
	align-self: stretch;
	display: grid;
	grid-template: subgrid / subgrid;
	align-items: center;
	justify-content: space-between;
	background-color: var(--component-background);

	@media #{dimen.$mobile} {
		display: flex;
		justify-content: space-between;

		& > .button {
			z-index: 2;
		}
	}

	.logo {
		flex: 0 1 100px;
	}
}

.app-header-brand {
	grid-column: sidebar-left;
	display: flex;
	align-items: center;
	column-gap: 2rem;
	padding-inline: dimen.$padding-inline;

	& > a {
		display: flex;
	}
}

.app-title {
	@extend .sr-only;
}

#header-nav {
	align-self: stretch;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 2rem;
	padding-inline: dimen.$padding-inline;
	grid-column: content;
	pointer-events: none;

	& > * {
		pointer-events: auto;
	}

	@media #{dimen.$mobile} {
		position: fixed;
		inset: 0;
		flex-direction: column;
		justify-content: flex-start;
		background-color: var(--background);
		z-index: 1;
		padding-top: 0.5rem;

		&[aria-expanded="false"] {
			display: none;
		}
	}

	.button {
		.button-label {
			font-weight: 400;
			border: none;
		}

		&::after {
			content: unset;
		}
	}

	.tabs {
		flex: 1;
		align-self: stretch;
	}
}