%accent {
	--0: #0075BB;
	--1: #3B54A6;
	--2: #458AAB;
	--3: #4D6B79;
	--4: #4F5D4C;
	--5: #71CFF1;
	--6: #843D1D;
	--7: #8A8566;
	--8: #A4D5A8;
	--9: #A53C36;
	--10: #B3A6D1;
	--11: #DA5127;
	--12: #DE3C36;
	--13: #E16326;
	--14: #EE1C25;
	--15: #F26949;
	--16: #F4734B;
	--17: #F4E901;
	--18: #F8E7CB;
	--19: #FA9F1B;
	--21: #FFC60A;
}