@use "vars/palette";
@use "partials/field";
@use "components/Message";

.input {
	@extend %field;

	&:focus-within {
		@extend %field-active;
	}

	&:has(input[type="search"]:placeholder-shown) {
		&::after {
			@extend %field-icon;
			content: 'search';
		}
	}

	.message {
		margin-top: 1ex;
	}
}

.input-label {
	@extend %field-title;
}

.input-field {
	@extend %field-inner;

	&:required {
		& + label {
			&::after {
				content: '*';
				color: palette.$clay-orange;
				margin-left: 0.125em;
			}
		}
	}
}

input.input-field {
	display: flex;
	align-items: center;
}

textarea.input-field {
	resize: vertical;
	align-self: stretch;
}