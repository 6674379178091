@use "common/source/styles/vars/dimen";
@use "common/source/styles/partials/theme";
@use "common/source/styles/type/copy";

#app-footer {
	@extend %light;
	display: grid;
	grid-template: subgrid / subgrid;
	align-items: center;
	color: var(--foreground);
	background-color: var(--background);

	@media #{dimen.$mobile} {
		grid-template-rows: repeat(2, auto);
	}
}

.footer-nav {
	grid-column: inner-content-start / content-end;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 2rem;
	padding: 2rem dimen.$padding-inline;

	@media #{dimen.$mobile} {
		grid-row: 1 / 2;
		grid-column: 1 / -1;
		justify-content: center;
		flex-wrap: wrap;
	}
}

.copyright {
	@extend %copy;
	grid-column: sidebar-left;
	text-align: center;
	padding: 2rem dimen.$padding-inline;

	a {
		color: var(--foreground);
		@media (hover: hover) {
			transition: color 100ms;
			&:hover {
				color: var(--action);
			}
		}

	}

	@media #{dimen.$mobile} {
		grid-row: -2 / -1;
		grid-column: 1 / -1;
		padding: 0 0 dimen.$padding-inline;
	}
}