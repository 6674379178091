@use "vars/dimen";
@use "partials/global";
@use "pages/Login";
@use "pages/Dashboard";

:root {
	--header: max(49px, 3.5rem);
	--sidebar: minmax(#{dimen.$element}, auto);
	--gutter: #{dimen.$padding-inline};
	--inner: 2fr;
}

#root .app-header-brand {
	padding: 0;
}