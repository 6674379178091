@use "common/source/styles/type/title";
@use "common/source/styles/type/label";
@use "common/source/styles/partials/icon";

%field {
	display: grid;
	grid-template-columns: [container-start] 1fr [icon-start] auto [icon-end container-end];
	grid-template-rows: [label-start] auto [label-end field-start] 1fr [field-end];
	align-items: center;
	color: var(--color);

	:where(&) {
		--color: var(--text-primary);
	}
}

%field-active {
	--color: var(--action);
	z-index: 1;
}

%field-title {
	@extend %title;
	grid-row: label;
	grid-column: container;
}

%field-inner {
	@extend %label;
	grid-row: field;
	grid-column: container;
	text-align: initial;
	background: none;
	box-sizing: border-box;
	min-height: 3rem;
	padding: 0.5rem;
	border-block: solid 2px transparent;
	border-inline: none;
	border-bottom-color: var(--color);
	outline: none;
}

%field-icon {
	@extend %icon;
	grid-row: field;
	grid-column: icon;
	color: var(--color);
	margin-inline: 1rem;
}