@use "vars/dimen";
@use "partials/accent";
@use "partials/accessibility";
@use "type/heading";

.list-filters {
	@extend %accent;
	position: relative;
	display: grid;
	grid-template: subgrid / subgrid;
	align-self: stretch;
	align-content: space-between;
	padding-inline: dimen.$padding-inline;
	overflow: auto;

	@media #{dimen.$desktop} {
		&::after {
			content: '';
			align-self: end;
			justify-self: center;
			display: block;
			background-image: linear-gradient(to right, var(--b1) 25%, var(--b2) 25%, var(--b2) 50%, var(--b3) 50%, var(--b3) 75%, var(--b4) 75%);
			background-repeat: no-repeat;
			background-size: dimen.$element * 4 dimen.$element;
			aspect-ratio: 4;
			background-position: bottom;
			grid-row: subfooter;
			width: 100%;
			height: 100%;
		}
	}
}

.list-title {
	@extend .sr-only;
}

#type {
	grid-row: subheader;
}

.filter-selectors {
	align-self: stretch;
	grid-row: inner-content;
	display: flex;
	flex-flow: column nowrap;
	row-gap: 3rem;

	@media #{dimen.$mobile} {
		position: absolute;
		inset-inline: 0;
		background-color: var(--window-background);
		grid-row: 1 / -1;
		padding: 1rem;

		&[aria-hidden='true'] {
			visibility: hidden;
		}
	}
}

.filter-selectors-header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > p {
		@extend %heading;
		color: var(--secondary);
	}
}