@use "vars/dimen";
@use "type/label";
@use "type/interacive";
@use "partials/accessibility";
@use "partials/icon";

.tabs {
	position: relative;
	display: grid;
	grid-template-columns: repeat(var(--count), minmax(6rem, 1fr));
	align-items: stretch;
	min-height: dimen.$element;
	box-sizing: border-box;
	max-width: 100%;
	background: var(--element-background);
	--toggle-color: var(--foreground);

	label {
		@extend %interactive;
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: dimen.$inline;
		text-align: center;
		white-space: nowrap;
		color: var(--text-secondary);
		box-sizing: border-box;
		padding-inline: dimen.$inline;
		transition: color 200ms, background-color 200ms;
		cursor: pointer;

		&[data-icon]::before {
			@extend %icon;
			content: attr(data-icon);
		}

		&:not(:last-of-type) {
			&::after {
				content: '';
				position: absolute;
				width: dimen.$divider;
				background-color: var(--text-disabled);
				height: 100%;
				right: -1px;
			}
		}
	}

	input {
		@extend .sr-only;

		&:checked + label {
			color: var(--toggle-color);
			cursor: default;
			transition: color 100ms;

			&::after {
				opacity: 1;
				transform: translate3d(0, 0, 0) !important;
				transition: opacity 100ms, transform 100ms;
			}
		}

		&:disabled + label {
			color: var(--text-disabled);
			cursor: not-allowed;
		}
	}

	@for $i from 0 through 4 {
		&[data-index="#{$i}"]::after {
			transform: translate3d(#{100% * $i}, 0, 0);
		}
	}

	&::after {
		content: '';
		position: absolute;
		height: 2px;
		bottom: 0;
		background: var(--toggle-color);
		width: calc(100% / var(--count));
		pointer-events: none;
		transition: transform 100ms 100ms;
	}
}
