@use "vars/dimen";
@use "type/title";
@use "partials/accessibility";
@use "components/Input";

.table {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: stretch;
	flex-direction: column;
	padding-inline: dimen.$padding-inline;

	.empty {
		@extend %title;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--text-tertiary);
	}

	table {
		border-collapse: collapse;
		width: 100%;
	}

	a {
		text-decoration: none;
	}

	th, td {
		border: none;

		&:has(> p) {
			text-align: center;
		}

		&:has(input[type="checkbox"]) {
			padding-inline: dimen.$inline !important;
			text-align: center;
		}
	}

	thead th {
		& > .button {
			display: inline-flex;
		}
		padding-inline: dimen.$padding-inline;
		border-bottom: solid 2px var(--foreground);
		padding-bottom: 1ex;

		& > p {
			color: var(--disabled);
		}
	}

	tbody {
		th, td {
			padding: 2.5ex dimen.$inline;
			border-bottom: 1px solid var(--disabled);
		}

		tr:last-child {th, td {
			border-bottom: none;
		}}
	}
}

.table-header {
	grid-row: header;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	column-gap: 1rem;
	margin-bottom: 4ex;

	.input-label {
		@extend .sr-only;
	}

	& > *:last-child {
		justify-self: end;
	}
}

.row-actions {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}