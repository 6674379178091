@use "sass:color";
@use "common/source/styles/vars/palette";

:root {
	--text-primary: #{palette.$eerie-black};
	--text-secondary: #{color.scale(palette.$eerie-black, $lightness: 20%)};
	--text-tertiary: #{color.scale(palette.$eerie-black, $lightness: 50%)};
	--window-background: #{palette.$ghost-white};
	--component-background: #{color.scale(palette.$ghost-white, $lightness: 20%)};
	--element-background: #{color.scale(palette.$ghost-white, $lightness: 50%)};
	--action: #{palette.$emeryville-blue};
	--accent: #{palette.$clay-orange};
}

%light {
	--background: #{palette.$ghost-white};
	--foreground: #{color.scale(palette.$eerie-black, $lightness: 20%)};
	--disabled: #{color.scale(palette.$eerie-black, $lightness: 70%)};
	--primary: #{palette.$eerie-black};
	--secondary: #{color.scale(palette.$eerie-black, $lightness: 20%)};
	--tertiary: #{color.scale(palette.$eerie-black, $lightness: 50%)};
}

%dark {
	--background: #{color.scale(palette.$eerie-black, $lightness: 20%)};
	--foreground: #{color.scale(palette.$ghost-white, $lightness: 20%)};
	--disabled: #{color.scale(palette.$ghost-white, $lightness: 70%)};
	--action: #{color.scale(palette.$emeryville-blue, $lightness: 50%)};
	--primary: #{palette.$ghost-white};
	--secondary: #{color.scale(palette.$ghost-white, $lightness: 20%)};
	--tertiary: #{color.scale(palette.$ghost-white, $lightness: 50%)};

	color: var(--foreground);
	background: var(--background);
}

