@use "sass:color";
@use "vars/palette";
@use "partials/icon";
@use "partials/accessibility";
@use "type/copy";
@use "type/small";
@use "partials/theme";

$debug: false;

$in: 250ms;
$out: 500ms;
$top: '[data-block="top"]';
$bottom: '[data-block="bottom"]';
$left: '[data-inline="left"]';
$right: '[data-inline="right"]';
$center: '[data-inline="center"]';
$padding-block: 12px;
$padding-inline: 16px;
$inset-inline: 16px;
$flag-size: 12px;
$flag-offset: $flag-size + $padding-block;

.tooltip {
	@extend %dark;
	position: absolute;
	z-index: 1;
	display: flex;
	flex-flow: column nowrap;
	gap: 8px;
	text-align: initial;
	white-space: initial;
	width: max-content;
	min-width: 2 * $flag-size + 2 * $padding-inline;
	max-width: 12rem;
	padding: $padding-block $padding-inline $padding-block + 2px;
	pointer-events: auto;
	transition: transform $in, opacity $in;

	p {
		@extend %copy;
		width: max-content;
		max-width: 100%;
	}

	ul {
		padding-left: 1em;
	}

	&#{$top} {
		bottom: 100%;
		padding-bottom: $flag-offset;

		&#{$right} {
			clip-path: polygon(
							0 0,
							100% 0,
							100% calc(100% - $flag-size),
							2 * $flag-size + $inset-inline calc(100% - $flag-size),
							$inset-inline + $flag-size 100%,
							$inset-inline calc(100% - $flag-size),
							0 calc(100% - $flag-size)
			);
			transform-origin: $inset-inline + $flag-size calc(100% - $flag-size);
		}

		&#{$left} {
			clip-path: polygon(
							0 0, 100% 0,
							100% calc(100% - $flag-size),
							calc(100% - $inset-inline) calc(100% - $flag-size),
							calc(100% - ($flag-size + $inset-inline)) 100%,
							calc(100% - (2 * $flag-size + $inset-inline)) calc(100% - $flag-size),
							0 calc(100% - $flag-size)
			);
			transform-origin: calc(100% - ($inset-inline + $flag-size)) calc(100% - $flag-size);
		}

		&#{$center} {
			clip-path: polygon(
							0 0,
							100% 0,
							100% calc(100% - $flag-size),
							calc(50% + $flag-size) calc(100% - $flag-size),
							50% 100%,
							calc(50% - $flag-size) calc(100% - $flag-size),
							0 calc(100% - $flag-size)
			);
			transform-origin: center bottom;
		}
	}

	&#{$bottom} {
		top: 100%;
		padding-top: $flag-offset;

		&#{$right} {
			clip-path: polygon(
							0 $flag-size,
							$inset-inline $flag-size,
							$inset-inline + $flag-size 0,
							2 * $flag-size + $inset-inline $flag-size,
							100% $flag-size, 100% 100%, 0 100%
			);
			transform-origin: $inset-inline + $flag-size $flag-size;
		}

		&#{$left} {
			clip-path: polygon(
							0 $flag-size,
							calc(100% - (2 * $flag-size + $inset-inline)) $flag-size,
							calc(100% - ($inset-inline + $flag-size)) 0,
							calc(100% - $inset-inline) $flag-size,
							100% $flag-size, 100% 100%, 0 100%
			);
			transform-origin: calc(100% - ($inset-inline + $flag-size)) $flag-size;
		}

		&#{$center} {
			clip-path: polygon(
							0 $flag-size, calc(50% - $flag-size) $flag-size, 50% 0, calc(50% + $flag-size) $flag-size, 100% $flag-size, 100% 100%, 0 100%
			);
			transform-origin: center top;
		}
	}

	&#{$left} {
		right: -$inset-inline + $padding-inline;
	}

	&#{$right} {
		left: -$inset-inline + $padding-inline;
	}

	&#{$center} {
		left: 50%;

		transform: translate3d(-50%, 0, 0);

		&[aria-hidden="true"] {
			@if $debug {
			} @else {
				transform: translate3d(-50%, 0, 0) scale3d(0, 0, 1);
			}
		}
	}

	&[aria-hidden="true"] {
		@if $debug {
		} @else {
			opacity: 0;
			transform: scale3d(0, 0, 1);
			transition: transform $out, opacity $out;
			pointer-events: none;
		}
	}
}

