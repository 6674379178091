@use "sass:color";
@use "vars/dimen";
@use "vars/palette";
@use "partials/accessibility";
@use "partials/field";
@use "type/heading";
@use "type/small";

.select {
	@extend %field;
	position: relative;

	&::after {
		@extend %field-icon;
		content: 'arrow_drop_down';
	}

	&:has([aria-expanded="true"]) {
		@extend %field-active;
	}

	&:focus-within {
		z-index: 2;
	}

	&[aria-controls] {
		.select-header {
			@extend .sr-only;
		}

		.select-trigger {
			@extend %heading;
			padding-block: 0;
			padding-inline: 0 2.5em;
		}
	}
}

.select-header {
	grid-column: 1 / -1;
	display: flex;
	align-items: center;
}

.select-title {
	@extend %field-title;
}

.select-trigger {
	@extend %field-inner;
	cursor: pointer;
}

.select-options {
	position: absolute;
	inset-inline: 0;
	top: 100%;
	flex-flow: column nowrap;
	row-gap: 0.25rem;
	background-color: var(--element-background);
	padding: 0.5rem 0;
	border-bottom: solid dimen.$divider var(--disabled);
	max-height: calc(#{dimen.$element} + 33vmin);
	overflow-y: auto;

	&[aria-hidden="true"] {
		display: none;
	}

	&[aria-hidden="false"] {
		display: flex;
	}
}

.select-option {
	--color: var(--foreground);
	display: contents;

	&[aria-current] {
		--color: var(--action);
	}

	input {
		@extend .sr-only;

		&[type="checkbox"] {
			&:checked + label {
				background-color: #{color.scale(palette.$emeryville-blue, $lightness: 80%)};
			}
		}
	}

	label {
		display: block;
		color: var(--color);
		padding: 1rem;
		cursor: pointer;

		small {
			@extend %small;
			color: var(--text-tertiary);
		}
	}
}
