//Breakpoints
$xxs-max: 320px;
$xs-min: $xxs-max + 1px;
$xs-max: 480px;
$sm-min: $xs-max + 1px;
$sm-max: 800px;
$md-min: $sm-max + 1px;
$md-max: 1440px;
$lg-min: $md-max + 1px;
$lg-max: 1920px;
$xl-min: $lg-max + 1px;
$xl-max: 2560px;
$xxl-min: $xl-max + 1px;

//Device Ranges
$xxs: "(max-width: #{$xxs-max})";
$xs: "(max-width: #{$xs-max})";
$sm: "(min-width: #{$sm-min}) and (max-width: #{$sm-max})";
$md: "(min-width: #{$md-min}) and (max-width: #{$md-max})";
$lg: "(min-width: #{$lg-min}) and (max-width: #{$lg-max})";
$xl: "(min-width: #{$xl-min})";
$xxl: "(min-width: #{$xxl-min})";

$sm-up: "(min-width: #{$sm-min})";
$md-up: "(min-width: #{$md-min})";
$lg-up: "(min-width: #{$lg-min})";

$sm-down: "(max-width: #{$sm-max})";
$md-down: "(max-width: #{$md-max})";
$lg-down: "(max-width: #{$lg-max})";

$padding-inline: clamp(1.2rem, 0.2667rem + 3.1111vw, 4rem);
$padding-inline-double: clamp(2.4rem, 0.5334rem + 6.222vw, 8rem);

$divider: 1px;
$element: 3rem;
$inline: 1rem;

$mobile: $sm-down;
$desktop: $md-up;