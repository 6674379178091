@use "vars/dimen";
@use "type/title";
@use "components/Page";
@use "components/Select";
@use "components/Button";
@use "components/Tabs";
@use "components/Filters";
@use "components/UserProfile";
@use "components/Table";
@use "components/Header";

.admin {
	display: grid;
	grid-template: subgrid / subgrid;

	.table-header {
		grid-column: inner-content-start / content-end;
		padding-inline: dimen.$padding-inline;
	}

	.table {
		grid-column: inner-content-start / content-end;
		grid-row: 2 / -1;
		overflow-x: auto;
	}

	.list-filters {
		grid-column: sidebar-left;
		grid-row: 1 / -1;
		row-gap: 2rem;
		padding-inline: 0;
		overflow: hidden;

		&::after {
			position: absolute;
			inset-block: 3rem;
			right: dimen.$divider * -0.5;
			display: block;
			background: var(--disabled);
			width: dimen.$divider;
			background-size: unset;
			background-position: unset;
			grid-row: unset;
			aspect-ratio: unset;
		}
	}
}

#header-nav {
	grid-column: inner-content-start / content-end;
}


