@use "vars/dimen";
@use "partials/accessibility";
@use "partials/accent";

body {
	display: grid;
	grid-template-rows:
			[window-start header-start]
			var(--header)
			[header-end content-start subheader-start]
			minmax(var(--header), auto)
			[subheader-end inner-content-start]
			minmax(auto, 1fr)
			[inner-content-end subfooter-start]
			minmax(var(--header), auto)
			[content-end footer-start]
			var(--header)
			[footer-end window-end];

	grid-template-columns:
			[window-start gutter-left-start]
			var(--gutter)
			[gutter-left-end content-start sidebar-left-start]
			var(--sidebar)
			[sidebar-left-end inner-content-start]
			var(--inner)
			[inner-content-end sidebar-right-start]
			var(--sidebar)
			[sidebar-right-end content-end gutter-right-start]
			var(--gutter)
			[gutter-right-end window-end];
	box-sizing: border-box;
	min-height: calc(100% - var(--header) / 3.5);
}

#root {
	display: contents;

	& > .message {
		z-index: 1;
		grid-row: header;
		grid-column: inner-content;
		align-self: center;
		justify-self: center;
	}
}

#content {
	@extend %accent;
	grid-row: content;
	grid-column: window;
	background: rgb(238, 238, 238);
	padding-block: max(2vmin, 1rem) max(4vmin, 3rem);
	isolation: isolate;
	align-self: stretch;
	width: 100%;
	box-sizing: border-box;

	:where(& > *) {
		box-sizing: border-box;
		overflow: hidden;
	}
}

:where(#content > *) {
	grid-column: content;
}

#app-header {
	grid-row: header;
	grid-column: window;
}

#app-footer {
	grid-row: footer;
	grid-column: window;
}

.nav-skip {
	@extend .sr-only;
}