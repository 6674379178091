%icon {
	direction: ltr;
	font-family: 'Material Symbols Outlined', 'Material Icons';
	font-size: 1.25em;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1;
	text-transform: none;
	white-space: nowrap;
	word-wrap: normal;
	vertical-align: middle;
	-webkit-font-feature-settings: 'liga';
	font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
	font-variation-settings: 'FILL' 0, 'opsz' 32, 'wght' 600;
}
