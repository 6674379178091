@use "components/Page";

#auth-form {
	display: flex;
	flex-flow: column nowrap;
	row-gap: 1.75rem;
}

#auth-form, .auth-title {
	grid-column: inner-content;
}

.Login {
	display: grid;
	grid-template: subgrid / subgrid;
}

.signup-footer {
	display: flex;
	flex-wrap: wrap-reverse;
	align-items: center;
	justify-content: space-between;
	column-gap: 1.75rem;
}

.shim {
	flex: 1 1 0;
}
