// Primary Colors
$emeryville-blue: #0076BB;
$ghost-white: #F9F4F9;
$eerie-black: #191919;

// Secondary Colors
$clay-orange: #DD6325;
$red: #EB1C24;
$rotten-tan: #F9E5C8;

$green: #A4D5A8;