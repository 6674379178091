@use "vars/consts";
@use "vars/dimen";
@use "type/interacive";
@use "partials/theme";
@use "partials/accessibility";
@use "common/source/styles/vars/palette";
@use "common/source/styles/partials/icon";
@use "components/Tooltip";

:where(.button) {
	--button-color: var(--primary);
}

.button {
	position: relative;
	display: flex;
	align-items: stretch;
	justify-content: center;
	column-gap: 1rem;
	text-decoration: none;
	box-sizing: border-box;
	height: dimen.$element;
	padding-block: 0;
	cursor: pointer;

	&:where([data-importance="primary"]) {
		color: var(--background);
		background-color: var(--button-color);
		padding-inline: 2rem;
		border: solid 2px var(--button-color);

		@media (hover: hover) {
			transition: color 250ms, background-color 250ms;

			&:hover {
				color: var(--button-color);
				background-color: transparent;
			}
		}
	}

	&:where([data-importance="secondary"], [data-importance="tertiary"]) {
		color: var(--button-color);
		background: none;
		padding-inline: 1rem;
		border: none;
	}

	&:where([data-importance="secondary"]) {
		@media (hover: hover) {
			&::after {
				content: '';
				position: absolute;
				inset-inline: 0;
				bottom: 0;
				background-color: var(--button-color);
				height: 2px;
				transform: scale3d(0.5, 1, 1);
				transition: transform 250ms;
			}

			&:hover {
				&::after {
					transform: scale3d(1, 1, 1);
				}
			}
		}
	}

	&:where([data-importance="tertiary"]) {
		--button-color: var(--text-tertiary);

		@media (hover: hover) {
			transition: --button-color 250ms;
			&:hover {
				--button-color: var(--text-secondary);
			}
		}
	}

	&[data-quality="good"] {
		--button-color: var(--action);
		.button-icon {
			color: var(--button-color);
		}
	}

	&[data-quality="bad"] {
		--button-color: #{palette.$red};
		color: var(--primary);
		.button-icon {
			color: var(--button-color);
		}
	}

	&:where([aria-current]) {
		--button-color: var(--text-tertiary);
	}

	&:where(&[data-icon-position="start" i]) {
		.button-icon {
			order: 1;
		}
	}

	&:where(&[data-icon-position="end" i]) {
		.button-icon {
			order: 3;
		}
	}

	&:where(&[data-icon-position="center" i]) {
		padding: 0;
		width: dimen.$element;
		.button-label {
			@extend .sr-only;
		}
	}
}

.button-label {
	@extend %interactive;
	order: 2;
	display: flex;
	align-items: center;
	column-gap: dimen.$inline;
	white-space: nowrap;

	.button[data-importance="secondary"] > & {
		border-block: solid 2px;
		border-top-color: transparent;
		border-bottom-color: var(--button-color);
	}
}

.button-icon {
	@extend %icon;
	display: inline-flex;
	align-items: center;
}