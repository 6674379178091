@use "vars/dimen";
@use "partials/theme";
@use "components/Header";
@use "components/Footer";

:root {
	font-family: sans-serif;
	font-size: 14px;
	line-height: 1.4142;
}

html {
	height: 100%;
}

body {
	@extend %light;
	color: var(--foreground);
	background-color: var(--background);
	overflow-x: hidden;
}

:where(*) {
	box-sizing: border-box;
	margin: 0;
}