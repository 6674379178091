@use "sass:color";
@use "vars/dimen";
@use "vars/palette";
@use "type/label";
@use "partials/theme";

.message {
	@extend %label;
	min-height: dimen.$element;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-self: stretch;
	color: var(--foreground);
	background-color: var(--background);
	border-bottom: solid dimen.$divider var(--foreground);
	padding: 1ex 1em;
	gap: dimen.$inline;

	& > .button {
		color: var(--foreground);
	}

	&[data-type="error"] {
		--foreground: #{palette.$red};
		--background: #{color.scale(palette.$red, $lightness: 90%)};
	}

	&[data-type="info"] {
		--foreground: #{color.scale(palette.$rotten-tan, $lightness: -80%)};
		--background: #{palette.$rotten-tan};
	}

	&[data-type="success"] {
		--foreground: #{color.scale(palette.$green, $lightness: -80%)};
		--background: #{palette.$green};
	}
}